* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

.BG {
  background: #000000;
  .app_container {
    max-width: 1980px;
    left: 0;
    right: 0;
    margin: auto;
  }
}
