@import url("https://fonts.googleapis.com/css2?family=Arima+Madurai:wght@300;500;700&display=swap");

.connect_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .border {
    border-color: #ffffff;
    border-style: dotted;
    border-radius: 15px;
    background: #22343d;
    padding: 0rem 2rem;

    p {
      color: white;
      padding: 0.5rem;
      font-family: "Arima Madurai", cursive;
      font-size: 25px;
      font-weight: 400;
      //text-shadow: 2px 2px 2px #030303;
      text-align: center;
      @media screen and (max-width: 1300px) {
        font-size: 22px;
      }
      @media screen and (max-width: 1000px) {
        font-size: 18px;
      }
      @media screen and (max-width: 700px) {
        font-size: 15px;
      }
      @media screen and (max-width: 450px) {
        font-size: 12px;
      }
    }
  }

  button {
    background-color: #6dbaff;
    color: white;
    font-size: 20px;
    line-height: 30px;
    border-radius: 11px;
    font-family: "Arima Madurai", cursive;
    padding: 0.5rem 1.5rem;
    font-weight: 100;
    filter: brightness(100%);
    cursor: pointer;
    margin-top: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
    transition: ease-in-out 0.2s;

    &:hover {
      background-color: white;
      //color: black;
      p {
        color: #6dbaff;
        -webkit-text-stroke-color: #6dbaff;
      }
    }
    &:active {
      background-color: #6dbaff;
      color: white;
      box-shadow: 0 5px #000000;
      transform: translateY(4px);
      p {
        color: white;
        -webkit-text-stroke-color: white;
      }
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 30px;
      //margin-top: -2px;
      color: #ffffff;
      //margin-left: 10px;
      //text-shadow: 2px 2px 2px #6868b1;
      @media screen and (max-width: 700px) {
        font-size: 15px;
        width: 100%;
        line-height: 20px;
        text-align: center;
        margin-left: 0px;
      }
    }
  }
}
.minting_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    color: white;
    font-size: 40px;
    margin-bottom: 2rem;
    font-family: "Arima Madurai", cursive;
    letter-spacing: 3px;
    //text-shadow: 2px 2px 2px #030303;
    text-align: center;
    @media screen and (max-width: 1000px) {
      font-size: 30px;
      margin-bottom: 2rem;
    }
    @media screen and (max-width: 450px) {
      font-size: 25px;
      margin-bottom: 1rem;
    }

    p {
      padding-top: 0.5rem;
      font-family: "Arima Madurai", cursive;
      //text-shadow: 2px 2px 2px #030303;
    }
  }
  .border {
    border-color: #ffffff;
    border-style: dotted;
    border-radius: 15px;
    background: #22343d;
    padding: 0rem 2rem;

    p {
      color: white;
      padding: 0.5rem;
      font-family: "Arima Madurai", cursive;
      font-size: 25px;
      font-weight: 400;
      //text-shadow: 2px 2px 2px #030303;
      text-align: center;
      @media screen and (max-width: 1000px) {
        font-size: 18px;
      }
      @media screen and (max-width: 700px) {
        font-size: 15px;
      }
      @media screen and (max-width: 450px) {
        font-size: 12px;
      }
    }
  }
}

.big_container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 5rem 2rem;
  margin-top: -4px;
  background-color: #1b3f51;

  @media screen and (max-width: 950px) {
    flex-direction: column;
    justify-content: center;
    padding: 2rem 0rem;
  }

  .left {
    @media screen and (max-width: 950px) {
      padding-bottom: 1rem;
    }
    .timer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 2rem 0;

      @media screen and (max-width: 950px) {
        flex-direction: column;
        justify-content: center;
        padding: 1rem 0;
      }

      .white {
        color: #ffffff;
        //text-decoration: underline;
        font-family: "Arima Madurai", cursive;
        letter-spacing: 1px;
        //text-shadow: 2px 2px 2px #030303;
        padding-right: 1rem;

        @media screen and (max-width: 1200px) {
          font-size: 20px;
        }
        @media screen and (max-width: 550px) {
          font-size: 25px;
        }
      }
    }
  }

  .mint {
    display: flex;
    justify-content: center;
  }

  .timer_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    //margin-bottom: 1rem;
    .line {
      all: unset;
      height: 6rem;
      background-color: white;
      border: 0.5px;
      border-style: solid;
      border-color: white;
      margin: 0 0.5rem;
      @media screen and (max-width: 1200px) {
        height: 4rem;
      }
      @media screen and (max-width: 700px) {
        height: 3rem;
      }
      @media screen and (max-width: 500px) {
        height: 2rem;
      }
    }
    div {
      h2 {
        font-size: 23px;
      }
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0rem 4rem;
      width: 5rem;
      //background: #ffffff;
      //margin: 1rem 0.5rem;
      //border: #030303 3px solid;
      //border-radius: 15px;
      font-family: "Arima Madurai", cursive;
      font-size: 25px;
      letter-spacing: 3px;
      color: white;

      @media screen and (max-width: 1300px) {
        padding: 0rem 2rem;
        font-size: 18px;
        h2 {
          font-size: 20px;
        }
      }
      @media screen and (max-width: 1000px) {
        padding: 0rem 1rem;
        font-size: 14px;
        h2 {
          font-size: 16px;
        }
      }
      @media screen and (max-width: 700px) {
        width: 4rem;
        font-size: 10px;
        h2 {
          font-size: 12px;
        }
      }
      @media screen and (max-width: 450px) {
        width: 3rem;
        height: 3.5rem;
        font-size: 7px;
        h2 {
          font-size: 10px;
        }
      }
    }
  }
}

.increase {
  width: 50px;
  height: 50px;
  font-size: 33px;
  background: #6dbaff;
  color: white;
  border-radius: 50px;
  font-weight: 800px;
  box-shadow: rgba(0, 0, 0, 0.8) 1.5px 1.5px 4px;
  cursor: pointer;
  @media screen and (max-width: 450px) {
    width: 40px;
    height: 40px;
    font-size: 25px;
  }
  &:active {
    background-color: #6dbaff;
    box-shadow: 0 5px #000000;
    transform: translateY(4px);
  }
}

.p-text {
  width: 100px;
  border: #000000 2px solid;
  border-radius: 15px;
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  @media screen and (max-width: 450px) {
    width: 80px;
    font-size: 20px;
  }
}

.mint_button {
  color: #ffffff;
  font-size: 25px;
  background: #6dbaff;
  font-family: "Arima Madurai", cursive;
  padding: 0.5rem 3.5rem;
  margin-top: 1rem;
  border-radius: 15px;
  letter-spacing: 1px;
  cursor: pointer;

  box-shadow: rgba(0, 0, 0, 0.8) 1.5px 1.5px 4px;

  @media screen and (max-width: 1000px) {
    padding: 0.5rem 2.5rem;
    font-size: 18px;
  }
  @media screen and (max-width: 450px) {
    padding: 0.5rem 2.5rem;
    font-size: 20px;
  }
  &:hover {
    background-color: #ffffff;
    color: #6dbaff;
  }
  &:active {
    background-color: #6dbaff;
    color: #ffffff;
    box-shadow: 0 5px black;
    transform: translateY(4px);
  }
}

.price {
  font-size: 20px !important;
  margin-top: 2rem;
  margin-bottom: 0 !important;
  @media screen and (max-width: 1000px) {
    font-size: 15px !important;
  }
  @media screen and (max-width: 450px) {
    margin-top: 1rem;
  }
}

.opensea_btn {
  color: #ffffff;
  font-size: 25px;
  background: rgb(74, 85, 242);
  font-family: "Arima Madurai", cursive;
  padding: 0.5rem 3.5rem;
  margin-top: 1rem;
  border-radius: 15px;
  letter-spacing: 1px;
  cursor: pointer;

  box-shadow: rgba(0, 0, 0, 0.8) 1.5px 1.5px 4px;

  @media screen and (max-width: 1000px) {
    padding: 0.5rem 2.5rem;
    font-size: 18px;
  }
  @media screen and (max-width: 450px) {
    padding: 0.5rem 2.5rem;
    font-size: 20px;
  }
  &:hover {
    background-color: #ffffff;
    color: #6dbaff;
  }
  &:active {
    background-color: rgb(74, 85, 242);
    color: #ffffff;
    box-shadow: 0 5px black;
    transform: translateY(4px);
  }
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
