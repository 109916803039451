.video_container {
  width: 100%;

  .player {
    width: 100%;
    display: block;
  }
}

.player_container {
  display: block;
  position: relative;
  transition: opacity 1s, display 1s, ease-in;

  .controls_pause {
    display: none !important;
  }
  .controls {
    display: block;
    color: #fff;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 200;
    transition: opacity 1s, display 1s, ease-in;
  }
  &:hover {
    .controls_pause {
      display: block !important;
      opacity: 0.5;
    }
  }
}
