.hero_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #3493c0;
  position: relative;

  /* background-image: url(../../assets/banner.gif);
  background-size: contain;
  background-repeat: no-repeat; */
  .arrow {
    width: 15%;
    position: absolute;
    bottom: 9%;
    left: 41%;
    animation-duration: 2s;
  }
  img {
    width: 100%;
  }
}

.social_container {
  color: #fff;
  position: absolute;
  right: 2rem;
  top: 2rem;
  animation-duration: 2s;
  @media screen and (max-width: 550px) {
    right: 1rem;
    top: 1rem;
  }
  .social {
    padding-right: 1rem;
    width: 4rem;
    cursor: pointer;
    @media screen and (max-width: 850px) {
      width: 2.5rem;
    }
    @media screen and (max-width: 550px) {
      width: 2rem;
    }
    &:hover {
      color: #ce2640;
    }
  }
}

.opensea {
  margin-left: 0.3rem;
width: 30% !important; 
cursor: pointer;
  @media screen and (max-width: 850px) {
    width: 33% !important;
      margin-left: -0.1rem;
      margin-bottom: 0.3rem;
  }
    @media screen and (max-width: 550px) {
      width: 25% !important;
        margin-left: -0.1rem;
        margin-bottom: 0.5rem;
    }
            &:hover {
              color: #ce2640;
            }

}
