@import url("https://fonts.googleapis.com/css2?family=Arima+Madurai:wght@300;500;700&display=swap");

:root {
  --animate-delay: 0.5s;
}

.info_container {
  //display: block;
  position: relative;
  width: 100%;
  margin-top: -0.5rem;
  //margin-top: -0.5rem;
  .background {
    width: 100%;
    position: relative;
    display: block;
  }
}
.infos {
  display: flex;
  flex-direction: row;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 55%;
  align-items: center;

  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 50%;
    margin-right: 1rem;

    .man {
      display: flex;
      opacity: 1 !important;
      width: 80%;
      @media screen and (max-width: 1000px) {
        width: 80%;
      }
    }
    .logo {
      opacity: 1 !important;
      display: flex;
      width: 80%;
      @media screen and (max-width: 1000px) {
        width: 80%;
      }
    }
    p {
      font-size: 1.7rem;
      width: 80%;
      text-align: center;
      font-family: "Arima Madurai", cursive;
      font-weight: 700;
      @media screen and (max-width: 1400px) {
        font-size: 1.5rem;
      }
      @media screen and (max-width: 1000px) {
        font-size: 1rem;
        line-height: 1.2rem;
      }
      @media screen and (max-width: 700px) {
        font-size: 0.5rem;
        line-height: 0.8rem;
      }
    }
    .social {
      display: flex;
      flex-direction: row;
      color: #fff;
      width: 40%;
      justify-content: center;
      align-items: center;
      opacity: 1 !important;

      margin-top: 2rem;
      @media screen and (max-width: 1000px) {
        margin-top: 1rem;
        width: 50%;
      }
      @media screen and (max-width: 700px) {
        margin-top: 0.5rem;
        width: 50%;
      }
      @media screen and (max-width: 500px) {
        margin-top: 0;
        width: 60%;
      }

      .social_logo {
        margin: 1rem 0.5rem;
        width: 4rem;
        height: 3rem;
        @media screen and (max-width: 1000px) {
          width: 3rem;
          height: 2rem;
        }
        @media screen and (max-width: 700px) {
          width: 2rem;
          height: 1rem;
          margin: 0.5rem 0.2rem;
        }

        &:hover {
          color: #fbdc8a;
        }
      }
    }
  }
}

.no_logo {
  opacity: 0;
  display: flex;
  width: 80%;
  @media screen and (max-width: 1000px) {
    width: 80%;
  }
}

.no_man {
  opacity: 0;
  display: flex;
  width: 80%;
  @media screen and (max-width: 1000px) {
    width: 80%;
  }
}

.no_social {
  opacity: 0;
}

a {
  color: #fff;
}

.no_word {
  opacity: 0;
}
