@import url("https://fonts.googleapis.com/css2?family=Arima+Madurai:wght@300;500;700&display=swap");
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #3493c0;

  img {
    width: 20%;
    margin-top: 1rem;

    @media screen and (max-width: 500px) {
      width: 30%;
    }
  }

  p {
    font-size: 1.3rem;
    color: white;
    margin-top: 0.3rem;
    margin-bottom: 1rem;
    font-family: "Arima Madurai", cursive;
    font-weight: 700;

    @media screen and (max-width: 1500px) {
      font-size: 1rem;
    }

    @media screen and (max-width: 1300px) {
      font-size: 0.8rem;
    }

    @media screen and (max-width: 1000px) {
      font-size: 0.5rem;
    }

    @media screen and (max-width: 600px) {
      font-size: 0.4rem;
    }
  }

  a {
    font-size: 1.3rem;
    color: white;
    margin-top: 0.3rem;
    //margin-bottom: 1rem;
    font-family: "Arima Madurai", cursive;
    font-weight: 700;

    @media screen and (max-width: 1500px) {
      font-size: 1rem;
    }

    @media screen and (max-width: 1300px) {
      font-size: 0.8rem;
    }

    @media screen and (max-width: 1000px) {
      font-size: 0.5rem;
    }

    @media screen and (max-width: 600px) {
      font-size: 0.4rem;
    }
  }
}
